<template>
  <div class="login-root">
    <index-component></index-component>


    <div class="lr-body">

      <div class="lrb-right">

<!--                <img class="lrb-right-icon" v-if="nowShow!==2&&nowShow!==5&&baseUrl.indexOf(hostname) > -1"-->
<!--                     src="~@/assets/image/index/code.png"-->
<!--                     @click="switchCode(2)" />-->
<!--                <img class="lrb-right-icon" v-if="nowShow===2&&nowShow!==5&&baseUrl.indexOf(hostname) > -1"-->
<!--                     src="~@/assets/image/index/mobile.png"-->
<!--                     @click="switchCode(1)" />-->
        <div class="lrb-right-main">
          <!--密码登录以及验证码登录tab-->
          <Tabs v-if="nowShow===1">
            <TabPane label="密码登录" name="name1">
              <input class="lrb-input" placeholder="请输入手机号码" v-model="passwordLoginPhone" />
              <form>
                <input class="lrb-input" autocomplete="false" type="password" placeholder="请输入用户密码"
                       v-model="passwordLoginPassword" />
              </form>

              <div class="lrb-verify lrb-verify-border">
                <input class="lrb-verify-input" placeholder="请输入验证码" maxlength="8" v-model="passwordLoginVerifyCode" />
                <img @click="getVerifyImage" width="100" alt="图形验证码" height="48" :src="verifyCodeImage" />
              </div>
              <div class="lrb-button-login" @click="passwordLogin">登录</div>
              <div class="lrb-ope">
                <div class="lrb-ope-left" @click="switchCode(3)">找回密码</div>
                <div class="lrb-ope-right" @click="switchCode(4)">尚未注册？</div>
              </div>

            </TabPane>
            <TabPane label="手机验证码登录" name="name2">
              <input class="lrb-input" placeholder="请输入手机号码" v-model="smsLoginPhone" />
              <div class="lrb-verify">
                <input class="lrb-verify-input-sms" placeholder="请输入手机验证码" maxlength="8"
                       v-model="smsLoginPhoneVerifyCode" />
                <div class="lrb-verify-sendsms" @click="sendMobileLoginVerifyCode">
                  {{ smsLoginVerifyTime === 61 ? "发送手机验证码" : smsLoginVerifyTime + "s" }}
                </div>
              </div>
              <div class="lrb-verify lrb-verify-border">
                <input class="lrb-verify-input" v-model="smsLoginImageVerifyCode" placeholder="请输入验证码" maxlength="8"
                />
                <img width="100" @click="getVerifyImage" alt="图形验证码" height="50" :src="verifyCodeImage" />
              </div>
              <div class="lrb-button-login" @click="mobileLogin">登录</div>
              <div class="lrb-ope">
                <div class="lrb-ope-left" @click="switchCode(3)"></div>
                <div class="lrb-ope-right" @click="switchCode(4)">尚未注册？</div>
              </div>
            </TabPane>
          </Tabs>

          <!--          注册tab-->
          <Tabs value="name1" v-if="nowShow===4">
            <TabPane label="会员注册" name="name1">
              <input class="lrb-input" placeholder="请输入手机号码" v-model="registerPhoneNumber" />
              <form>
                <input autocomplete="false" class="lrb-input" type="password" placeholder="请设置用户密码"
                       v-model="registerPassword" />
              </form>

              <div class="lrb-verify">
                <input class="lrb-verify-input-sms" placeholder="请输入验证码" maxlength="8" v-model="registerVerifyCode" />
                <div @click="sendRegisterPhoneVerifyCode" class="lrb-verify-sendsms">
                  {{ registerVerifyTime === 61 ? "发送手机验证码" : registerVerifyTime + "s" }}
                </div>
              </div>
              <div class="lrb-button-login" @click="registerClick">注册</div>
              <div class="lrb-ope">
                <div class="lrb-ope-left"></div>
                <div class="lrb-ope-right" @click="switchCode(1)">返回登录</div>
              </div>
            </TabPane>
          </Tabs>

          <!--      找回密码tabs-->
          <Tabs value="name1" v-if="nowShow===3">
            <TabPane label="密码重置" name="name1">
              <input class="lrb-input" placeholder="请输入手机号码" v-model="resetPasswordPhone" />

              <div class="lrb-verify">
                <input class="lrb-verify-input-sms" placeholder="请输入手机验证码" v-model="resetPasswordVerifyCode"
                       maxlength="8" />
                <div class="lrb-verify-sendsms" @click="sendResetPasswordVerifyCode">
                  {{ resetPasswordVerifyTime === 61 ? "发送手机验证码" : resetPasswordVerifyTime + "s" }}
                </div>
              </div>
              <form>
                <input autocomplete="false" type="password" class="lrb-input" placeholder="请设置用户密码"
                       v-model="resetPasswordPwd" />
              </form>
              <div class="lrb-button-login" @click="resetPassword">重置密码</div>
              <div class="lrb-ope">
                <div class="lrb-ope-left"></div>
                <div class="lrb-ope-right" @click="switchCode(1)">返回登录</div>
              </div>
            </TabPane>
          </Tabs>
          <!--        扫二维码登录-->
          <Tabs value="name1" v-show="nowShow===2">
            <TabPane label="扫二维码登录" name="name1">
<!--              <div id="wechat_login_container" style="display: flex;justify-content: center;height: 400px"></div>-->
             <img src="https://open.weixin.qq.com/connect/qrcode/011ZHVBR3oIE000l"  width="100%" height="300"/>
              <div style="font-size: 14px;color: #333333;text-align: center">使用微信扫一扫功能,扫描此二维码</div>
            </TabPane>
          </Tabs>

          <!--          绑定手机号tabs-->
          <Tabs value="name1" v-if="nowShow===5">
            <TabPane label="绑定手机" name="name1">
              <input class="lrb-input" placeholder="请输入手机号码" v-model="thirdBindPhone" />
              <form>
                <input autocomplete="false" class="lrb-input" type="password" placeholder="请输入用户密码"
                       v-model="thirdBindPwd"
                />
              </form>

              <div class="lrb-verify">
                <input class="lrb-verify-input-sms" placeholder="请输入验证码" maxlength="8" v-model="thirdBindVerifyCode" />
                <div @click="sendThirdBindVerifyCode" class="lrb-verify-sendsms">
                  {{ thirdBindVerifyTime === 61 ? "发送手机验证码" : thirdBindVerifyTime + "s" }}
                </div>
              </div>
              <div class="lrb-button-login" @click="wxLoginBindPhone">绑定</div>
            </TabPane>
          </Tabs>
        </div>
      </div>


    </div>

    <footer-component white-bg="true"></footer-component>

  </div>
</template>

<script>
import {
  errorToast,
  isCorrectPassword,
  isPhoneNumber,
  isVerifyCode,
  successToast,
  warnToast
} from "@/plugins/tools/util";
import { wxAppId, wxCssStyle } from "@/plugins/tools/Constant";
// import erwei from '@/assets/image/index/erwei.png'
import {
  checkLogin,
  getVerifyCodeByPhone,
  userPasswordLogin,
  userRegisterAccount,
  userResetPassword,
  weChatCode,
  userSmsLogin, wxLoginBindPhone, wxLoginGetAccessToken,
  getExtraVerifyCodeByPhone
} from "../plugins/api/loginApi";
import axios from "axios";
import baseUrl from "../plugins/http/baseUrl";
import { mapMutations, mapActions } from "vuex";
import { getLocalToken } from "../plugins/tools/storage";
import { hostname, domain } from "../config";

axios.defaults.withCredentials = true;
//单独的axios返回处理
// 响应拦截器
export default {
  name: "LoginCom",
  props: ["type"],
  components: {
    "indexComponent": () => import("../components/index/indexHeader"),
    "footerComponent": () => import("../components/index/homePageComponent/footerComponent")
  },
  created() {
    const code = this.$route.query.code;
    if (code && code.length > 0) {
      this.nowShow = 5;
    }
  },
  data() {
    return {
      baseUrl: "",
      siteTitle: "",
      hostname,
      siteUrl: "",
      siteKeywords: "",
      siteDescription: "",
      erwei: "",
      erweiFlag: false,
      sessionId: "",
      nowShow: 1,//1.密码登录2.扫码登录3.找回密码4.注册 5.第三方登录绑定手机
      registerVerifyCode: "",//注册tab的验证码
      registerPhoneNumber: "",//注册tab的手机号码
      registerPassword: "",//注册tab的密码
      verifyCodeImage: "",//图形验证码
      registerVerifyTime: 61,//注册界面验证码倒计时60S
      passwordLoginPhone: "",//密码登录手机号
      passwordLoginPassword: "",//密码登录密码
      passwordLoginVerifyCode: "",//密码登录图形验证码
      smsLoginPhone: "",//手机验证码登录手机号
      smsLoginPhoneVerifyCode: "",//手机验证码登录短信验证码
      smsLoginImageVerifyCode: "",//手机验证码登录图片验证码
      smsLoginVerifyTime: 61,//密码登录界面验证码倒计时
      resetPasswordPhone: "",//找回密码手机号
      resetPasswordVerifyCode: "",//找回密码手机验证码
      resetPasswordPwd: "",//找回密码设置的新密码
      resetPasswordVerifyTime: 61,//找回密码验证码倒计时
      thirdBindPhone: "",//第三方登录绑定手机号
      thirdBindVerifyCode: "",//第三方登录绑定手机验证码
      thirdBindPwd: "",//第三方登录设置的密码
      thirdBindVerifyTime: 61,//第三方登录验证码倒计时
      wxOpenId: ""//微信登录需要的openid
    };
  },
  mounted() {
    this.baseUrl = baseUrl;
    axios.get(`${baseUrl}/v1/site/one`).then(res => {
      if (res.status === 200) {
        let data = res.data.result;
        this.siteUrl = data.logoImageUrl;
        this.siteTitle = data.title;
        this.siteKeywords = data.keywords;
        this.siteDescription = data.description;
        this.erwei = data.qrCode;
      }
    });
    const code = this.$route.query.code;
    if (code && code.length > 0) {
      this.wxLoginGetAccessToken();
      return;
    }
    this.nowShow = this.type;
    // new WxLogin({
    //   self_redirect: false,
    //   id: "wechat_login_container",
    //   appid: wxAppId,
    //   scope: "snsapi_login",
    //   redirect_uri: "http%3A%2F%2Fwww." + hostname + "%2F%23%2Flogin",
    //   state: "",
    //   style: "",
    //   href: wxCssStyle
    // });
    this.getVerifyImage();

  },
  computed: {},
  methods: {
    goEnterprise() {
      checkLogin(this.$router);
      if (!this.token || !this.userId) {
        return;
      }
      if (this.enterpriseState === "CERTIFIED") {
        this.$router.push({
          name: "enterprise-pgId",
          params: {
            pgId: "enterprise-management-center"
          }
        });
      } else {
        this.$router.push({
          name: "enterprise-EnterpriseCertification"
        });
      }

    },
    ...mapMutations({
      setUserToken: "userStore/setUserToken",
      setUserName: "userStore/setUserName",
      setUserAccountType: "userStore/setUserAccountType",
      setUserMbrMemberId: "userStore/setUserMbrMemberId",
      setUserId: "userStore/setUserId",
      setUserEnterpriseState: "userStore/setUserEnterpriseState"
    }),
    //返回首页
    goIndex() {
      this.$router.push({
        path: "/"
      });
    },
    //切换展示的页面
    switchCode(number) {
      this.nowShow = number;
    },

    //获取图形验证码
    async getVerifyImage() {
      const json = await axios.get(`${baseUrl}/v1/getVerifyImage`, {
        responseType: "arraybuffer"
      });
      this.sessionId = json.headers.sessionid;
      this.verifyCodeImage = "data:image/png;base64," + btoa(
        new Uint8Array(json.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
      );
    },


    //登录
    async passwordLogin() {
      const { passwordLoginPhone, passwordLoginPassword, passwordLoginVerifyCode } = this;
      if (!passwordLoginPhone) {
        warnToast("请输入手机号");
        return;
      }

      if (!passwordLoginPassword) {
        warnToast("请输入密码");
        return;
      }
      if (!passwordLoginVerifyCode) {
        warnToast("请输入验证码");
        return;
      }
      if (!isPhoneNumber(passwordLoginPhone)) {
        warnToast("请输入正确的手机号");
        return;
      }
      let data = {
        sessionId: this.sessionId,
        username: passwordLoginPhone,
        password: passwordLoginPassword,
        verifyCode: passwordLoginVerifyCode
      };
      const json = await userPasswordLogin(JSON.stringify(data));
      if (json && json.code === 0) {
        this.loginSuccessOperate(json);
      } else {
        errorToast(json.message || "登录失败");
      }
    },
    openZoosUrl() {
      openZoosUrl("chatwin");
    },
    //登陆成功之后的操作
    loginSuccessOperate(json) {
      //将token存入localStorage

      this.setUserToken(json.result.token);
      this.$cookies.set("token", json.result.token, null, null, domain);
      this.setUserName(json.result.username);
      this.setUserId(json.result.id);
      if (json.result.accountType) {
        this.setUserAccountType(json.result.accountType);
      }
      if (json.result.mbrMemberId) {
        this.setUserMbrMemberId(json.result.mbrMemberId);
      }
      if (json.result.member && json.result.member.enterpriseState) {
        this.setUserEnterpriseState(json.result.member.enterpriseState);
      }

      if (getLocalToken()) {
        successToast("登陆成功");
        this.goIndex();
      }
    },
    //手机验证码登录
    async mobileLogin() {
      const { smsLoginPhone, smsLoginPhoneVerifyCode, smsLoginImageVerifyCode } = this;
      if (!smsLoginPhone) {
        warnToast("请输入手机号");
        return;
      }
      if (!isPhoneNumber(smsLoginPhone)) {
        warnToast("请输入正确的手机号");
        return;
      }

      if (!smsLoginPhoneVerifyCode || !isVerifyCode(smsLoginPhoneVerifyCode)) {
        warnToast("请输入六位手机验证码");
        return;
      }
      if (!smsLoginImageVerifyCode) {
        warnToast("请输入图形验证码");
        return;
      }
      let data = {
        sessionId: this.sessionId,
        phone: smsLoginPhone,
        code: smsLoginPhoneVerifyCode,
        validate: smsLoginImageVerifyCode
      };
      const json = await userSmsLogin(JSON.stringify(data));
      if (json && json.code === 0) {
        this.loginSuccessOperate(json);
      } else {
        errorToast(json.message || "登录失败");
      }

    },
    //手机登录界面发送手机验证码
    async sendMobileLoginVerifyCode() {
      if (!this.smsLoginPhone || !isPhoneNumber(this.smsLoginPhone)) {
        warnToast("请填写正确的手机号");
        return;
      }
      if (this.smsLoginVerifyTime <= 60) {
        return;
      }
      let params = {
        phone: this.smsLoginPhone
      };
      const json = await getVerifyCodeByPhone(params);
      if (json && json.code === 0) {
        successToast("短信验证码已发送");
        this.smsLoginVerifyTimeCode();
      } else {
        errorToast(json.message || "发送失败");
      }
    },

    //手机登录界面发送短信之后60S倒计时
    smsLoginVerifyTimeCode() {
      this.smsLoginVerifyTime = 60;
      const timer = setInterval(() => {
        this.smsLoginVerifyTime--;
        if (this.smsLoginVerifyTime === 0) {
          clearInterval(timer);
          this.smsLoginVerifyTime = 61;
        }
      }, 1000);
    },

    //发送注册界面手机验证码
    async sendRegisterPhoneVerifyCode() {
      if (!isPhoneNumber(this.registerPhoneNumber) || !this.registerPhoneNumber) {
        warnToast("请填写正确的手机号");
        return;
      }
      if (this.registerVerifyTime <= 60) {
        return;
      }
      let params = {
        phone: this.registerPhoneNumber
      };
      const json = await getExtraVerifyCodeByPhone(params);
      if (json && json.code === 0) {
        successToast("短信验证码已发送");
        this.registerVerifyTimeCode();
      } else {
        errorToast(json.message || "发送失败");
      }
    },


    //注册界面发送短信之后60S倒计时
    registerVerifyTimeCode() {
      this.registerVerifyTime = 60;
      const timer = setInterval(() => {
        this.registerVerifyTime--;
        if (this.registerVerifyTime === 0) {
          clearInterval(timer);
          this.registerVerifyTime = 61;
        }
      }, 1000);
    },

    //注册
    async registerClick() {
      const { registerVerifyCode, registerPhoneNumber, registerPassword } = this;
      if (!registerPhoneNumber) {
        warnToast("请输入手机号");
        return;
      }
      if (!registerPassword) {
        warnToast("请输入密码");
        return;
      }
      if (!isPhoneNumber(registerPhoneNumber)) {
        warnToast("请输入正确的手机号码");
        return;
      }
      if (!isCorrectPassword(registerPassword)) {
        warnToast("请输入8-16位数字字母组合密码");
        return;
      }
      if (!registerVerifyCode || !isVerifyCode(registerVerifyCode)) {
        warnToast("请输入六位手机验证码");
        return;
      }

      let data = {
        code: registerVerifyCode,
        password: registerPassword,
        phone: registerPhoneNumber
      };
      const json = await userRegisterAccount(JSON.stringify(data));
      if (json && json.code === 0) {
        successToast("注册成功");
        this.switchCode(1);
        this.passwordLoginPassword = registerPassword;
        this.passwordLoginPhone = registerPhoneNumber;
      } else {
        errorToast(json.message || "注册失败");
      }

    },

    //找回密码界面发送手机验证码
    async sendResetPasswordVerifyCode() {
      if (!isPhoneNumber(this.resetPasswordPhone) || !this.resetPasswordPhone) {
        warnToast("请填写正确的手机号");
        return;
      }
      if (this.resetPasswordVerifyTime <= 60) {
        return;
      }
      let params = {
        phone: this.resetPasswordPhone
      };
      const json = await getVerifyCodeByPhone(params);
      if (json && json.code === 0) {
        successToast("短信验证码已发送");
        this.resetPasswordVerifyTimeCode();
      } else {
        errorToast(json.message || "发送失败");
      }
    },

    //找回密码界面发送短信之后60S倒计时
    resetPasswordVerifyTimeCode() {
      this.resetPasswordVerifyTime = 60;
      const timer = setInterval(() => {
        this.resetPasswordVerifyTime--;
        if (this.resetPasswordVerifyTime === 0) {
          clearInterval(timer);
          this.resetPasswordVerifyTime = 61;
        }
      }, 1000);
    },

    //重置密码
    async resetPassword() {
      const { resetPasswordPhone, resetPasswordVerifyCode, resetPasswordPwd } = this;
      if (!resetPasswordPhone) {
        warnToast("请输入手机号");
        return;
      }
      if (!isPhoneNumber(resetPasswordPhone)) {
        warnToast("请输入正确的手机号");
        return;
      }
      if (!resetPasswordVerifyCode) {
        warnToast("请输入验证码");
        return;
      }
      if (!isVerifyCode(resetPasswordVerifyCode)) {
        warnToast("请输入六位手机验证码");
        return;
      }
      if (!resetPasswordPwd) {
        warnToast("请输入密码");
        return;
      }
      if (!isCorrectPassword(resetPasswordPwd)) {
        warnToast("请输入8-16位数字字母组合密码");
        return;
      }
      let data = {
        code: resetPasswordVerifyCode,
        password: resetPasswordPwd,
        phone: resetPasswordPhone
      };
      const json = await userResetPassword(JSON.stringify(data));
      if (json && json.code === 0) {
        successToast("修改密码成功");
        this.switchCode(1);
      } else {
        errorToast(json.message || "修改密码失败");
      }
    },
    //第三方登录页面发送手机验证码
    async sendThirdBindVerifyCode() {
      if (!isPhoneNumber(this.thirdBindPhone) || !this.thirdBindPhone) {
        warnToast("请填写正确的手机号");
        return;
      }
      if (this.thirdBindVerifyTime <= 60) {
        return;
      }
      let params = {
        phone: this.thirdBindPhone
      };
      const json = await weChatCode(params);
      if (json && json.code === 0) {
        successToast("短信验证码已发送");
        this.setVerifyTimeCode();
      } else {
        errorToast(json.message || "发送失败");
      }
    },

    //发送短信之后60S倒计时
    setVerifyTimeCode() {
      this.thirdBindVerifyTime = 60;
      const timer = setInterval(() => {
        this.thirdBindVerifyTime--;
        if (this.thirdBindVerifyTime === 0) {
          clearInterval(timer);
          this.thirdBindVerifyTime = 61;
        }
      }, 1000);
    },


    //微信登录获取accesstoken
    async wxLoginGetAccessToken() {
      let params = {
        code: this.$route.query.code
      };
      const json = await wxLoginGetAccessToken(params);
      if (json && json.code === 0) {
        // 如果绑定过手机号直接登录跳首页
        this.loginSuccessOperate(json);
      }
      if (json && json.code === 1) {
        this.wxOpenId = json.result.wxOpenId;
      }
    },
    //微信登录绑定手机号
    async wxLoginBindPhone() {
      const { thirdBindPhone, thirdBindVerifyCode, thirdBindPwd, wxOpenId } = this;
      if (!thirdBindPhone) {
        warnToast("请输入手机号");
        return;
      }
      if (!thirdBindPwd) {
        warnToast("请输入密码");
        return;
      }
      if (!isPhoneNumber(thirdBindPhone)) {
        warnToast("请输入正确的手机号码");
        return;
      }
      if (!isCorrectPassword(thirdBindPwd)) {
        warnToast("请输入8-16位数字字母组合密码");
        return;
      }
      if (!thirdBindVerifyCode || !isVerifyCode(thirdBindVerifyCode)) {
        warnToast("请输入六位手机验证码");
        return;
      }
      const params = {
        "openid": wxOpenId,
        "password": thirdBindPwd,
        "phone": thirdBindPhone,
        "smsCode": thirdBindVerifyCode
      };
      const json = await wxLoginBindPhone(JSON.stringify(params));
      if (json && json.code === 0) {
        successToast("绑定成功");

        this.goIndex();
        //将token存入localStorage
        this.setUserToken(json.result.token);
        this.$cookies.set("token", json.result.token, null, null, "." + hostname);
        this.setUserName(json.result.username);
        this.setUserAccountType(json.result.accountType);
        this.setUserId(json.result.id);
        if (json.result.mbrMemberId) {
          this.setUserMbrMemberId(json.result.mbrMemberId);
        }
        if (json.result.member && json.result.member.enterpriseState) {
          this.setUserEnterpriseState(json.result.member.enterpriseState);
        }
      } else {
        errorToast(json.message || "绑定手机号失败");
      }
    }
  }
};
</script>

<style scoped>

.lrb-input:focus {
  border: 1px solid #DCDCDC;
  box-shadow: none !important;
  outline: none !important;

}


.lrb-verify-input:focus {
  border: 1px solid #DCDCDC;
  border: none;
  outline: none !important;
}

.lrb-verify-input-sms:focus {
  border: 1px solid #DCDCDC;
  outline: none !important;
}
.lr-body {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-image: url("../assets/image/index/login_bg.png");
  background-size: 100% 100%;
  align-items: center;
  min-height: calc(100vh - 240px);
}

.lrb-right {
  width: 400px;
  height: 500px;
  background-color: white;
  box-shadow: 0 0 10px rgba(91, 91, 91, .5);
  padding: 15px 30px 22px 30px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 12px;
  margin-right: 230px;
}

.lrb-right-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  z-index: 999;
}

.lrb-right-main {
  flex: 1;
}

.lrb-input {
  height: 50px;
  width: 100%;
  padding-left: 20px;
  border: 1px solid #DCDCDC;
  margin-top: 20px;
}

.lrb-verify {
  display: flex;
  flex-direction: row;
  height: 50px;
  margin-top: 20px;
}

.lrb-verify-border {
  border: 1px solid #DCDCDC;
}

.lrb-verify-sendsms {
  width: 115px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
  background-color: #007EE5;
  margin-left: 11px;
}

.lrb-verify-input {
  height: 48px;
  flex: 1;
  padding-left: 20px;
  border: none;

}

.lrb-verify-input-sms {
  flex: 1;
  border: 1px solid #DCDCDC;
  padding-left: 20px;
}

.lrb-verify img {
  border-left: 1px solid #DCDCDC;
  cursor: pointer;
}

.lrb-button-login {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007EE5;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;
}

.lrb-ope {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.lrb-ope-left {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  cursor: pointer;
}

.lrb-ope-right {
  font-size: 14px;
  font-weight: 400;
  color: #007EE5;
  cursor: pointer;
}


.lrb-bottom-main img {
  cursor: pointer;
}


.lr-footer-one span {
  font-size: 16px;
  font-family: Microsoft YaHei, serif;
  font-weight: 400;
  color: #FFFFFF;
  margin-right: 25px;
}


.lr-footer-two span {
  font-size: 14px;
  font-weight: 500;
  color: #C8C8C8;
  margin-right: 30px;
  margin-top: 20px;
}


</style>
